<template>
    <div>
        <div class="modal-backdrop fade show"></div>
        <!-- Custom field modal -->
        <div class="modal custom-fields show" id="new-user-modal" style="display: block">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
<!--                    v-on-click-away="closeModal"-->
                    <div class="modal-header">
                        <h4 class="modal-title text-capitalize">{{ $t("candidate onboard")}}</h4>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click.prevent="closeModal()">
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="form-group">
                            <label>{{ $t("Joining Date")}}*</label>
                            <div class="date-picker">
                                <date-picker
                                        :disabled="disableInputs"
                                        id="joining_date"
                                        v-model="form.joining_date"
                                        :placeholder="$t('mm/dd/yyyy')"
                                        autocomplete="off"
                                        :config="datePickerOptions"
                                ></date-picker>
                            </div>
                            <error-message :message="$t(errors.joining_date)"/>
                        </div>
                        <div class="form-group">
                            <label class="text-capitalize" for="assigned_salary">{{ $t("assigned salary")}}*</label>
                            <input type="text"
                                   id="assigned_salary"
                                   :class="{ 'has-error': errors.assigned_salary }"
                                   name="assigned_salary"
                                   v-model="form.assigned_salary"
                                   class="form-control"
                                   :placeholder="$t('1000 USD')"
                                   :disabled="disableInputs"
                            />
                            <error-message :message="$t(errors.assigned_salary)"/>
                        </div>
                        <div class="form-group">
                            <label class="text-capitalize" for="provision_period">{{ $t("provision period")}}*</label>
                            <input type="text"
                                   id="provision_period"
                                   :class="{ 'has-error': errors.provision_period }"
                                   name="provision_period"
                                   v-model="form.provision_period"
                                   class="form-control"
                                   :placeholder="$t('three-month')"
                                   :disabled="disableInputs"
                            />
                            <div class="form-note">
                                <span class="text-capitalize">{{ $t("ex")}}: </span> {{$t("three-month")}}, {{$t("six-month")}}
                            </div>
                            <error-message :message="$t(errors.provision_period)"/>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <div class="w-100 d-flex justify-content-between align-items-center">
                            <div class="mr-auto">
                                <label class="checkbox w-100">
                                    <input type="checkbox" v-model="form.systemOnboard"/>
                                    <span style="color: #597dfc; font-size:.75rem;">{{$t('Onboard without above information.')}}</span>
                                </label>
                                <span class="text" style="font-size:.75rem;" v-if="!form.systemOnboard">{{ $t('Note: easy.jobs will send email to candidate.') }}</span>
                                <span class="text" style="font-size:.75rem;" v-else>{{ $t('Note: easy.jobs will not send email to candidate.') }}</span>
                            </div>
                            <submit-button :click="onboardCandidate" type="success-button" :loading="isLoading">{{ $t("Onboard")}}</submit-button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
    import client from "../../../app/api/Client";
    import ErrorMessage from "../../common/ErrorMessage";

    import {datePickerIcons} from "../../../config/options";
    import datePicker from 'vue-bootstrap-datetimepicker';
    import moment from "moment";
    import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';
    import {mapActions} from "vuex";
    import { UPDATE_PAGE_STATE } from "../../../constants/action-type";

    export default {
        components: {
            ErrorMessage,
            datePicker
        },
        props: ['applicantIds', 'jobId'],
        data() {
            return {
                form: {
                    systemOnboard: false,
                    applicants: [],
                    assigned_salary: '',
                    provision_period: '',
                    joining_date: ''
                },
                errors: {
                    assigned_salary: '',
                    provision_period: '',
                    joining_date: ''
                },
                isLoading: false
            }
        },
        computed: {
            datePickerOptions() {
                return {
                    format: 'MM/DD/YYYY',
                    icons: datePickerIcons,
                    useCurrent: false
                }
            },
            disableInputs() {
               return  this.form.systemOnboard;
            }
        },
        methods: {
            ...mapActions([UPDATE_PAGE_STATE]),

            closeModal() {
                this.$emit('closeModal');
            },
            selectAll() {
                this.selected = this.allSelected ? Object.keys(this.permissions) : [];
            },
            async onboardCandidate() {
                this.isLoading = true;
                try {
                    this.form.joining_date = moment(this.form.joining_date, 'MM/DD/YYYY').format('L');
                    let {data: {data, message}} = await client().post(`job/${this.jobId}/candidate/onboard`, this.form);
                    this.$toast.success(message);
                    this.$emit('afterComplete');

                    this.UPDATE_PAGE_STATE({'showOnBoardingModal':false});

                } catch (err) {
                    if (err.response.status === 422) {
                        this.errors = _helper.serializeValidationMessage(err.response.data.message);
                        this.$toast.error(this.$t('Oops! Please check your input'));
                    } else {
                        this.$toast.error(this.$t(err.response.data.message));
                    }
                }
                this.isLoading = false;
            }
        },
        created() {
            this.form.applicants = this.applicantIds;
        }
    };
</script>

<style scoped>
    .modal-body {
        overflow-y: unset !important;
    }
</style>
